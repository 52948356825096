.login-form {
  width: 280px;
  padding: 32px 30px;
  margin: 22vh auto;
}

.login-form .login-inputs {
  margin: 32px 0;
}

.login-form .MuiFormControl-root {
  margin: 4px 0;
}

.login-form button {
  display: block;
}
