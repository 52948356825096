.sidebar-toolbar {
  height: 122px;
  background: white
    url("https://assets.website-files.com/5f5160e10c4f1657445078a3/5f521130dfb6e7d9e80382ea_srec-logo-dark.svg")
    no-repeat center center;
  background-size: 100px auto;
}

@media screen and (max-width: 700px) {
  .data-area-header {
    flex-direction: column;
    align-items: stretch !important;
  }
  .data-area-actions button {
    display: block;
    margin: 10px 0;
  }
  .data-area-actions {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}
